var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import MembersContext from "../../../application/Context/MembersContext";
import { FilterTitle } from "../shared-components/SideFilter/MultiSelection/MultiSelection-styles";
import { DropDownIndicatore } from "../shared-components/DropDownIndicatore";
import RemoteMultiSelect from "../shared-components/MultiSelect/RemoteMultiSelect";
// import MultiSelectLocal from "../shared-components/MultiSelect/MultiSelectLocal";
import Select, { components } from "react-select";
export var Filters = function () {
    var t = useTranslation(["news"]).t;
    var _a = useContext(MembersContext), handleScianChange = _a.handleScianChange, selectedSector = _a.selectedSector, handleTypeMembreChnage = _a.handleTypeMembreChnage, typeMembre = _a.typeMembre, companyType = _a.companyType, handleNorthMrcChange = _a.handleNorthMrcChange, selectedMrcNorth = _a.selectedMrcNorth, handleNorthRegionChange = _a.handleNorthRegionChange, selectedRegionNorth = _a.selectedRegionNorth, filtersIsLoading = _a.filtersIsLoading;
    var colourStylesRow = {
        dropdownIndicator: function (styles) { return (__assign(__assign({}, styles), { color: "#223654" })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { fontSize: "1rem", backgroundColor: state.isSelected ? "#19406c" : "white", color: state.isSelected ? "white" : "#223654", ":hover": {
                backgroundColor: "#19406c",
                color: "#223654",
            } })); },
        placeholder: function (provided) { return (__assign(__assign({}, provided), { fontSize: "1rem", color: "#647287" })); },
    };
    var DropdownIndicator = function (props) {
        return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(DropDownIndicatore, {}) })));
    };
    var findCompanyType = function () {
        if (typeMembre[0]) {
            var type_selected = companyType.find(function (_a) {
                var n = _a[0];
                return n === typeMembre[0].value;
            });
            if (type_selected) {
                return {
                    value: typeMembre[0].value,
                    label: type_selected[1],
                };
            }
        }
        return "";
    };
    var customLabelFunction = function (option, langCode) {
        return "".concat(option["code"], " - ").concat(option["title_".concat(langCode)]);
    };
    return (_jsxs(React.Fragment, { children: [_jsx("label", __assign({ htmlFor: "react-select-3-input", style: { display: "none" } }, { children: t("NAICS Code") })), _jsx(RemoteMultiSelect, { title: t("NAICS Code"), placeHolder: t("Select one or more NAICS codes"), handleClick: handleScianChange, SelectedChoices: selectedSector, disabled: filtersIsLoading, relatedField: "code_scian_primaire,code_scian_secondaire", ESindex: "Company", column: ["code", "title"], operator: ["istartswith", "icontains"], valueColumn: "code", getCustomLabel: customLabelFunction, customFilterType: "scian" }), _jsx(FilterTitle, { children: t("Types de membres") }), _jsx("label", __assign({ htmlFor: "react-select-5-input", style: { display: "none" } }, { children: t("Types de membres") })), _jsx(Select, { placeholder: t("Sélectionner un ou des types"), styles: colourStylesRow, classNamePrefix: "pn-select", options: companyType.map(function (item) { return ({
                    value: item[0],
                    label: item[1],
                }); }), isSearchable: false, onChange: handleTypeMembreChnage, value: findCompanyType(), components: { DropdownIndicator: DropdownIndicator, IndicatorSeparator: function () { return null; } }, isDisabled: filtersIsLoading }), _jsx("div", { className: "mb-3" }), _jsx("label", __assign({ htmlFor: "react-select-6-input", style: { display: "none" } }, { children: t("Région") })), _jsx(RemoteMultiSelect, { title: t("Région"), placeHolder: t("Sélectionner une ou des régions"), handleClick: handleNorthRegionChange, SelectedChoices: selectedRegionNorth, disabled: filtersIsLoading, relatedField: "adresses.region_adminsitrative", ESindex: "Company", column: "nom", operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option.nom; }, others: [{ column: "in_north_region", value: false }] }), _jsx("label", __assign({ htmlFor: "react-select-7-input", style: { display: "none" } }, { children: "MRC" })), _jsx(RemoteMultiSelect, { title: t("MRC"), placeHolder: t("Sélectionner une ou des MRC"), handleClick: handleNorthMrcChange, SelectedChoices: selectedMrcNorth, disabled: filtersIsLoading, relatedField: "adresses.mrc", ESindex: "Company", column: "nom", operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option.nom; }, others: [{ column: "in_north", value: false }] })] }));
};
