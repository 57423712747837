var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { ProjectsCard } from "../Cards/ProjectsCard";
import { InfoBar } from "../InfoBar";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../shared-components/NotFound";
import { Pagination } from "../../../react-components/projects-components/Pagination";
import { motion } from "framer-motion";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
export var Avis = function () {
    var _a = useContext(ProjectsContext), projects = _a.projects, projectsIsLoading = _a.projectsIsLoading, count = _a.count;
    return projectsIsLoading ? (_jsx(Loading, {})) : projects.length > 0 ? (_jsxs(React.Fragment, { children: [_jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: [_jsx(InfoBar, { elements: count }), projects.map(function (project, i) {
                        return (_jsx(ProjectsCard, { item: project }, i));
                    })] })), _jsx(Pagination, {})] })) : (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), _jsx(NotFound, {})] }));
};
