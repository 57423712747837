var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { motion } from "framer-motion";
import AdvancedSearchContext from "../../../../application/Context/AdvancedSearchContext";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
import NewsSearchContext from "../../../../application/Context/NewsSearchContext";
import MembersContext from "../../../../application/Context/MembersContext";
import DocLibContext from "../../../../application/Context/DocLibContext";
import EventsSearchContext from "../../../../application/Context/EventsSearchContext";
import BonsCoupsContext from "../../../../application/Context/BonsCoupsContext";
import WorkersContext from "../../../../application/Context/WorkersContext";
import { ProjectsCard } from "../Cards/ProjectsCard";
import { CompaniesCard } from "../Cards/CompaniesCard";
import { NewsCard } from "../Cards/NewsCard";
import { DocumentsCard } from "../Cards/DocumentsCard";
import { EventsCard } from "../Cards/EventsCard";
import { BonsCoupsCard } from "../Cards/BonsCoupsCard";
import { WorkersCard } from "../Cards/WorkersCard";
import { InfoBar } from "../InfoBar";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../shared-components/NotFound";
import { Pagination } from "../Pagination";
export var All = function () {
    var _a = useContext(AdvancedSearchContext), data = _a.data, dataIsLoading = _a.dataIsLoading, count = _a.count, navigationItemsParam = _a.navigationItemsParam, handleTab = _a.handleTab;
    var ProjectsSetAdvSearch = React.useContext(ProjectsContext).setAdvSearch;
    var MarketsSetAdvSearch = React.useContext(MarketsContext).setAdvSearch;
    var MembersSetAdvSearch = React.useContext(MembersContext).setAdvSearch;
    var NewsSetAdvSearch = React.useContext(NewsSearchContext).setAdvSearch;
    var DocsSetAdvSearch = React.useContext(DocLibContext).setAdvSearch;
    var EventsSetAdvSearch = React.useContext(EventsSearchContext).setAdvSearch;
    var BonsCoupsSetAdvSearch = React.useContext(BonsCoupsContext).setAdvSearch;
    var WorkersSetAdvSearch = React.useContext(WorkersContext).setAdvSearch;
    var params = new URLSearchParams(window.location.search);
    React.useEffect(function () {
        if (params.has("tab")) {
            var ptab = navigationItemsParam.indexOf(params.get("tab"));
            if (ptab >= 0) {
                handleTab({ target: { id: ptab } });
            }
            window.history.replaceState(null, "", window.location.href.split("?")[0]);
        }
    }, []);
    React.useEffect(function () {
        ProjectsSetAdvSearch(true);
        MarketsSetAdvSearch(true);
        MembersSetAdvSearch(true);
        NewsSetAdvSearch(true);
        DocsSetAdvSearch(true);
        EventsSetAdvSearch(true);
        BonsCoupsSetAdvSearch(true);
        WorkersSetAdvSearch(true);
    }, []);
    return dataIsLoading ? (_jsx(Loading, {})) : data.length > 0 ? (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), data.map(function (item, i) {
                if (item.table === "projects-index") {
                    return (_jsx(ProjectsCard, { item: item }, i));
                }
                else if (item.table === "companies-index") {
                    return (_jsx(CompaniesCard, { item: item }, i));
                }
                else if (item.table === "news-index") {
                    return (_jsx(NewsCard, { item: item }, i));
                }
                else if (item.table === "documents-index") {
                    return (_jsx(DocumentsCard, { item: item }, i));
                }
                else if (item.table === "events-index") {
                    return (_jsx(EventsCard, { item: item }, i));
                }
                else if (item.table === "bons-coups-index") {
                    return (_jsx(BonsCoupsCard, { item: item }, i));
                }
                else if (item.table === "workers-index") {
                    return (_jsx(WorkersCard, { item: item }, i));
                }
                else {
                    return (_jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: item.table }), i));
                }
            }), _jsx(Pagination, {})] })) : (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), _jsx(NotFound, {})] }));
};
