import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import WorkersContext from "../../../../application/Context/WorkersContext";
import { WorkersCard } from "../Cards/WorkersCard";
import { InfoBar } from "../InfoBar";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../shared-components/NotFound";
import { Pagination } from "../../../react-components/workers-components/Pagination";
export var Contact = function () {
    var _a = useContext(WorkersContext), workers = _a.workers, workersIsLoading = _a.workersIsLoading, count = _a.count;
    return workersIsLoading ? (_jsx(Loading, {})) : workers.length > 0 ? (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), workers.map(function (item, i) {
                return (_jsx(WorkersCard, { item: item }, i));
            }), _jsx(Pagination, {})] })) : (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), _jsx(NotFound, {})] }));
};
