var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { NewsCard } from "../Cards/NewsCard";
import NewsSearchContext from "../../../../application/Context/NewsSearchContext";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../search-news-components/Views/NotFound";
import { InfoBar } from "../InfoBar";
import { Pagination } from "../../../react-components/search-news-components/Pagination";
import { motion } from "framer-motion";
export var News = function () {
    var _a = React.useContext(NewsSearchContext), news = _a.news, newsIsLoading = _a.newsIsLoading, count = _a.count;
    return newsIsLoading ? (_jsx(Loading, {})) : news.length > 0 ? (_jsxs("div", __assign({ "data-display-res": "list" }, { children: [_jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: [_jsx(InfoBar, { elements: count }), news.map(function (newsItem, i) {
                        return (_jsx(NewsCard, { item: newsItem }, i));
                    })] })), _jsx(Pagination, {})] }))) : (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), _jsx(NotFound, {})] }));
};
