var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import Theme from "../components/react-components/Styles/Theme";
import { Container, FiltersContainer, ContentContainer, } from "../components/react-components/shared-components/Ui/Grid/Grid";
import { FilterCard } from "../components/react-components/shared-components/SideFilter/FilterCard";
import { SearchInput } from "../components/react-components/advanced-search-components/SearchInput";
import { Title } from "../components/react-components/shared-components/Title/Title";
import { Navigation } from "../components/react-components/advanced-search-components/Navigation/Navigation";
import { Views } from "../components/react-components/advanced-search-components/Views/Views";
import { AdvancedSearchProvider } from "./Context/AdvancedSearchContext";
import { Filters } from "../components/react-components/advanced-search-components/Filters";
import { CleanFilters } from "../components/react-components/advanced-search-components/CleanFilters";
import { ProjectsProvider } from "./Context/ProjectsContext";
import { MarketsProvider } from "./Context/MarketsContext";
import { MembersProvider } from "./Context/MembersContext";
import { NewsSearchProvider } from "./Context/NewsSearchContext";
import { DocLibProvider } from "./Context/DocLibContext";
import { EventsSearchProvider } from "./Context/EventsSearchContext";
import { BonsCoupsProvider } from "./Context/BonsCoupsContext";
import { WorkersProvider } from "./Context/WorkersContext";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
export var AdvancedSearch = function () {
    var t = useTranslation(["titles"]).t;
    React.useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(ProjectsProvider, { children: _jsx(MarketsProvider, { children: _jsx(MembersProvider, { children: _jsx(NewsSearchProvider, { children: _jsx(DocLibProvider, { children: _jsx(EventsSearchProvider, { children: _jsx(BonsCoupsProvider, { children: _jsx(WorkersProvider, { children: _jsx(AdvancedSearchProvider, { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsxs(Container, { children: [_jsxs(FiltersContainer, { children: [_jsx(SearchInput, {}), _jsxs(FilterCard, { children: [_jsx(CleanFilters, {}), _jsx(Filters, {})] })] }), _jsxs(ContentContainer, { children: [_jsx(Title, { title: t("Recherche avancée") }), _jsx(Navigation, {}), _jsx(Views, {})] })] }) })) }) }) }) }) }) }) }) }) }) })) })));
};
var domNode = document.getElementById("advanced-search");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(AdvancedSearch, {}));
}
