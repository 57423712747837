var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Tag, DateContainer, EventsContainer, EventsTitle, EventsImage } from "../../Styles/SharedStyledComponents";
import { Row } from "../../shared-components/Ui/Row";
export var EventsCard = function (_a) {
    var _b;
    var item = _a.item;
    var t = useTranslation(["titles"]).t;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var options = { year: "numeric", month: "long" };
    var f = Intl.DateTimeFormat(langCode, options);
    return (_jsx(React.Fragment, { children: _jsx(EventsContainer, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3 mb-md-0" }, { children: _jsx("a", __assign({ href: langCode == "fr" ? item.absolute_url_fr : item.absolute_url_en }, { children: _jsx(EventsImage, { children: _jsx("img", { src: item.thumbnail ? item.thumbnail : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }) }) })) })), _jsx("div", __assign({ className: "col-md-7" }, { children: _jsxs(Text, { children: [_jsx(DateContainer, { children: f.format(new Date(item.date_debut)) }), _jsx(EventsTitle, { children: _jsx("a", __assign({ href: langCode == "fr" ? item.absolute_url_fr : item.absolute_url_en }, { children: langCode == "fr" ? item.titre_event_fr : item.titre_event_en })) }), item.organisateur && (_jsxs("p", { children: [" ", t("Organized by"), " ", item.organisateur] })), _jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), alt: "location" }), _jsx("span", { children: item.region })] }), _jsx("div", __assign({ className: "d-flex flex-wrap mb-0" }, { children: [item.categorie].map(function (cat) { return (_jsx(Tag, { children: langCode == "fr" ? cat.nom_fr : cat.nom_en }, cat.id)); }) }))] }) }))] }) }) }));
};
