var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { SearchBarContainer } from "../shared-components/searchInput/search-input-styles";
import AdvancedSearchContext from "../../../application/Context/AdvancedSearchContext";
import ProjectsContext from "../../../application/Context/ProjectsContext";
import MarketsContext from "../../../application/Context/MarketsContext";
import MembersContext from "../../../application/Context/MembersContext";
import NewsSearchContext from "../../../application/Context/NewsSearchContext";
import DocLibContext from "../../../application/Context/DocLibContext";
import EventsSearchContext from "../../../application/Context/EventsSearchContext";
import BonsCoupsContext from "../../../application/Context/BonsCoupsContext";
import WorkersContext from "../../../application/Context/WorkersContext";
export var SearchInput = function () {
    var t = useTranslation(["advanced-search"]).t;
    var _a = React.useContext(AdvancedSearchContext), tab = _a.tab, setAllSearch = _a.handleSearchInput, AllSearch = _a.handleSearch, AdvSearchIsLoading = _a.dataIsLoading;
    var _b = React.useContext(ProjectsContext), ProjectsSetSearch = _b.handleSearchInput, ProjectsSearch = _b.searchProjects, ProjectsIsLoading = _b.projectsIsLoading;
    var _c = React.useContext(MarketsContext), MarketsSetSearch = _c.handleSearchInput, MarketsSearch = _c.searchProjects, MarketsIsLoading = _c.projectsIsLoading;
    var _d = React.useContext(MembersContext), MembersSetSearch = _d.handleSearchInput, MembersSearch = _d.searchCompanies, MembersIsLoading = _d.companiesIsLoading;
    var _e = React.useContext(NewsSearchContext), NewsSetSearch = _e.handleSearchInput, NewsSearch = _e.searchNews, NewsIsLoading = _e.newsIsLoading;
    var _f = React.useContext(DocLibContext), DocsSetSearch = _f.handleSearchInput, DocsSearch = _f.searchDocs, DocsIsLoading = _f.docsIsLoading;
    var _g = React.useContext(EventsSearchContext), EventsSetSearch = _g.handleSearchInput, EventsSearch = _g.searchEvents, EventsIsLoading = _g.eventsIsLoading;
    var _h = React.useContext(BonsCoupsContext), BonsCoupsSetSearch = _h.handleSearchInput, BonsCoupsSearch = _h.searchBonsCoups, BonsCoupsIsLoading = _h.bonsCoupsIsLoading;
    var _j = React.useContext(WorkersContext), WorkersSetSearch = _j.handleSearchInput, WorkersSearch = _j.searchWorkers, WorkersIsLoading = _j.workersIsLoading;
    var _k = __read(React.useState(""), 2), query = _k[0], setQuery = _k[1];
    var _l = __read(React.useState(false), 2), isLoading = _l[0], setIsLoading = _l[1];
    var handleQuery = function (e) {
        e.preventDefault();
        var value = e.target.elements.search.value;
        setQuery(value);
        setAllSearch(value);
        ProjectsSetSearch({ target: { value: value } }, tab);
        MarketsSetSearch({ target: { value: value } }, tab);
        MembersSetSearch({ target: { value: value } }, tab);
        NewsSetSearch({ target: { value: value } }, tab);
        DocsSetSearch({ target: { value: value } }, tab);
        EventsSetSearch({ target: { value: value } }, tab);
        BonsCoupsSetSearch({ target: { value: value } }, tab);
        WorkersSetSearch({ target: { value: value } }, tab);
    };
    React.useEffect(function () {
        if (tab == 0) {
            AllSearch();
        }
        else if (tab == 1) {
            ProjectsSearch();
        }
        else if (tab == 2) {
            MarketsSearch();
        }
        else if (tab == 3) {
            MembersSearch();
        }
        else if (tab == 4) {
            NewsSearch();
        }
        else if (tab == 5) {
            DocsSearch();
        }
        else if (tab == 6) {
            EventsSearch();
        }
        else if (tab == 7) {
            BonsCoupsSearch();
        }
        else if (tab == 8) {
            WorkersSearch();
        }
    }, [query]);
    React.useEffect(function () {
        setIsLoading(AdvSearchIsLoading || ProjectsIsLoading || MarketsIsLoading || MembersIsLoading || NewsIsLoading || DocsIsLoading || EventsIsLoading || BonsCoupsIsLoading || WorkersIsLoading);
    }, [AdvSearchIsLoading, ProjectsIsLoading, MarketsIsLoading, MembersIsLoading, NewsIsLoading, DocsIsLoading, EventsIsLoading, BonsCoupsIsLoading, WorkersIsLoading]);
    return (_jsx(React.Fragment, { children: _jsx("form", __assign({ onSubmit: handleQuery }, { children: _jsxs(SearchBarContainer, __assign({ disabled: isLoading }, { children: [_jsx("input", { name: "search", placeholder: t("Recherche"), className: "mb-4 mt-2", disabled: isLoading }), _jsx("button", __assign({ type: "submit", className: "mb-4 mt-2", disabled: isLoading }, { children: isLoading ?
                            _jsx("div", { className: "spinner-border spinner-border-sm text-light", role: "status" })
                            :
                                _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/recherche.svg"), alt: "search" }) }))] })) })) }));
};
