var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AdvancedSearchContext from "../../../application/Context/AdvancedSearchContext";
import ProjectsContext from "../../../application/Context/ProjectsContext";
import MarketsContext from "../../../application/Context/MarketsContext";
import NewsSearchContext from "../../../application/Context/NewsSearchContext";
import MembersContext from "../../../application/Context/MembersContext";
import DocLibContext from "../../../application/Context/DocLibContext";
import EventsSearchContext from "../../../application/Context/EventsSearchContext";
import BonsCoupsContext from "../../../application/Context/BonsCoupsContext";
import { CleanFilterBtn } from "../doc-lib-components/Views/Filters-styles";
import { useTranslation } from "react-i18next";
export var CleanFilters = function () {
    var t = useTranslation(["news"]).t;
    var _a = React.useContext(AdvancedSearchContext), displayedFilter = _a.displayedFilter, dataIsLoading = _a.dataIsLoading;
    var _b = React.useContext(ProjectsContext), ProjectsResetFilters = _b.resertFilters, ProjectsFiltersIsLoading = _b.filtersIsLoading;
    var _c = React.useContext(MarketsContext), MarketsResetFilters = _c.resertFilters, MarketsFiltersIsLoading = _c.filtersIsLoading;
    var _d = React.useContext(MembersContext), MembersResetFilters = _d.resetFilters, MembersFiltersIsLoading = _d.filtersIsLoading;
    var _e = React.useContext(NewsSearchContext), NewsResetFilters = _e.resetFilters, NewsFiltersIsLoading = _e.filtersIsLoading;
    var _f = React.useContext(DocLibContext), DocsResetFilters = _f.resetFilters, DocsFiltersIsLoading = _f.filtersIsLoading;
    var _g = React.useContext(EventsSearchContext), EventsResetFilters = _g.resetFilters, EventsFiltersIsLoading = _g.filtersIsLoading;
    var _h = React.useContext(BonsCoupsContext), BonsCoupsResetFilters = _h.resetFilters, BonsCoupsFiltersIsLoading = _h.filtersIsLoading;
    var doResetFilters = function () {
        if (displayedFilter == "Tous") {
            ProjectsResetFilters();
            MarketsResetFilters();
            MembersResetFilters();
            NewsResetFilters();
            DocsResetFilters();
            EventsResetFilters();
        }
        else if (displayedFilter == "Avis") {
            ProjectsResetFilters();
        }
        else if (displayedFilter == "Marchés") {
            MarketsResetFilters();
        }
        else if (displayedFilter == "Membres") {
            MembersResetFilters();
        }
        else if (displayedFilter == "Nouvelles") {
            NewsResetFilters();
        }
        else if (displayedFilter == "Documents") {
            DocsResetFilters();
        }
        else if (displayedFilter == "Événements") {
            EventsResetFilters();
        }
        else if (displayedFilter == "Bons Coups") {
            BonsCoupsResetFilters();
        }
    };
    return (!dataIsLoading && !ProjectsFiltersIsLoading && !MarketsFiltersIsLoading && !MembersFiltersIsLoading && !NewsFiltersIsLoading && !DocsFiltersIsLoading && !EventsFiltersIsLoading && !BonsCoupsFiltersIsLoading ? _jsx(CleanFilterBtn, __assign({ onClick: doResetFilters }, { children: t("Effacer les filtres") })) : _jsx("div", { className: "spinner-border spinner-border-sm", role: "status" }));
};
