var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var NavigationContainer = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nlist-style: none;\nmargin:0;\npadding:0;\ndisplay:flex;\njustify-content:space-between;\nmargin-top:20px;\nfont-size:1rem;\nfont-family:'Open Sans',sans-serif;\ncolor: ", ";\nborder-bottom: 1px solid #707070;\npadding:0 9px;\nscroll-behavior: smooth;\nscroll-x: auto;\noverflow-x: scroll;\ntext-wrap: nowrap;\n&::-webkit-scrollbar {\n    display: none;\n}\n\n\n& li{\n    padding:12px 6px;\n    cursor:pointer;\n    white-space: nowrap;\n}\n& li.active{\n    font-weight:bold;\n    border-bottom: 5px solid ", ";\n \n}\n\n"], ["\nlist-style: none;\nmargin:0;\npadding:0;\ndisplay:flex;\njustify-content:space-between;\nmargin-top:20px;\nfont-size:1rem;\nfont-family:'Open Sans',sans-serif;\ncolor: ", ";\nborder-bottom: 1px solid #707070;\npadding:0 9px;\nscroll-behavior: smooth;\nscroll-x: auto;\noverflow-x: scroll;\ntext-wrap: nowrap;\n&::-webkit-scrollbar {\n    display: none;\n}\n\n\n& li{\n    padding:12px 6px;\n    cursor:pointer;\n    white-space: nowrap;\n}\n& li.active{\n    font-weight:bold;\n    border-bottom: 5px solid ", ";\n \n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
export var NavigationContainerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nposition:relative;\n"], ["\nposition:relative;\n"])));
export var SearchNavButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nposition: absolute;\ntop: 12px;\nborder: none;\nbackground-color: white;\ncursor: pointer;\n\n&.right{\n    right: 0;\n    margin-right: -16px;\n    \n}\n&.left{\n    left: 0;\n    margin-left: -16px;\n\n}\n"], ["\nposition: absolute;\ntop: 12px;\nborder: none;\nbackground-color: white;\ncursor: pointer;\n\n&.right{\n    right: 0;\n    margin-right: -16px;\n    \n}\n&.left{\n    left: 0;\n    margin-left: -16px;\n\n}\n"])));
var templateObject_1, templateObject_2, templateObject_3;
