var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigationContainer, NavigationContainerWrapper, SearchNavButton } from "./navigation-styles";
import AdvancedSearchContext from "../../../../application/Context/AdvancedSearchContext";
export var Navigation = function () {
    var _a, _b;
    var t = useTranslation(["advanced-search"]).t;
    var _c = useContext(AdvancedSearchContext), handleTab = _c.handleTab, tab = _c.tab, navigationItems = _c.navigationItems;
    //search nav scroll
    var _d = __read(useState(0), 2), navScroll = _d[0], setNavScroll = _d[1];
    var _e = __read(useState(false), 2), showSearchButtons = _e[0], setShowSearchButtons = _e[1];
    var searchScrollRef = useRef(null);
    var searchMaxScrollWidth = ((_a = searchScrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollWidth) - ((_b = searchScrollRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth);
    var handleScrollClick = function (scrollAmount) {
        var newNavScroll = Math.max(0, Math.min(navScroll + scrollAmount, searchMaxScrollWidth));
        setNavScroll(newNavScroll);
        if (searchScrollRef.current) {
            searchScrollRef.current.scrollLeft = newNavScroll;
        }
    };
    var handleResize = function () {
        var _a, _b;
        if (((_a = searchScrollRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) < ((_b = searchScrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollWidth)) {
            setShowSearchButtons(true);
        }
        else {
            setShowSearchButtons(false);
        }
    };
    useEffect(function () {
        handleResize();
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx(React.Fragment, { children: _jsxs(NavigationContainerWrapper, { children: [_jsx(NavigationContainer, __assign({ ref: searchScrollRef }, { children: navigationItems.map(function (item, idnx) {
                        return (_jsx("li", __assign({ id: idnx, onClick: handleTab, className: tab === idnx ? "active" : "" }, { children: t(item) }), idnx));
                    }) })), showSearchButtons && (_jsx(_Fragment, { children: navScroll === 0 ? (_jsx(SearchNavButton, __assign({ onClick: function () { return handleScrollClick(50); }, className: "right" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-right.svg"), alt: "arrow-right" }) }))) : navScroll >= searchMaxScrollWidth ? (_jsx(SearchNavButton, __assign({ onClick: function () { return handleScrollClick(-50); }, className: "left" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-left.svg"), alt: "arrow-left" }) }))) : (_jsxs(_Fragment, { children: [_jsx(SearchNavButton, __assign({ onClick: function () { return handleScrollClick(-50); }, className: "left" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-left.svg"), alt: "arrow-left" }) })), _jsx(SearchNavButton, __assign({ onClick: function () { return handleScrollClick(50); }, className: "right" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-right.svg"), alt: "arrow-right" }) }))] })) }))] }) }));
};
