var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// import { useTranslation } from "react-i18next";
import { ContactContainer, Title, Text, ContactInfo, CompanyDetails, Description, IconContainer, Category, CompanyTitle } from "../Views/views-styles";
import { Row } from "../../shared-components/Ui/Row";
import { CompanyImageContainer } from "../../Styles/SharedStyledComponents";
export var WorkersCard = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var item = _a.item;
    // const { t } = useTranslation(["common"]);
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = langCode == "fr" ? "/fr" : "/en";
    var formatPhone = function (phone) {
        var match = "".concat(phone).match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3]);
        }
        return "".concat(phone);
    };
    return (_jsx(React.Fragment, { children: _jsxs(ContactContainer, { children: [_jsxs(Text, { children: [_jsx(Title, { children: (item.user.first_name || item.user.last_name) ? "".concat(item.user.first_name, " ").concat(item.user.last_name) : item.user.email }), _jsx("p", { children: item.poste }), item.user && item.user.email && (_jsxs(ContactInfo, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/envelope-blue-fonce.svg"), alt: "email" }), _jsx("a", __assign({ href: "mailto:".concat(item.user.email) }, { children: item.user.email }))] })), item.entreprise && item.entreprise.site_web && (_jsxs(ContactInfo, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/mouse-blue-fonce.svg"), alt: "website" }), _jsx("a", __assign({ href: "".concat(item.entreprise.site_web), target: "_blank", rel: "noreferrer" }, { children: item.entreprise.site_web }))] })), item.telephone_country_code && item.telephone_national_number && (_jsxs(ContactInfo, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/phone-handset-blue-fonce.svg"), alt: "telephone" }), _jsx("a", __assign({ href: "tel:+".concat(item.telephone_country_code).concat(item.telephone_national_number) }, { children: formatPhone(item.telephone_national_number) }))] }))] }), (item.entreprise.is_member && ["actived", "imported"].includes(item.entreprise.status)) &&
                    _jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-5 ps-0" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode == "fr" ? item.entreprise_absolute_url_fr : item.entreprise_absolute_url_en) }, { children: _jsxs(CompanyImageContainer, __assign({ marginBottom: 14 }, { children: [_jsx("img", { src: item.entreprise.logo ? item.entreprise.logo : "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: item.entreprise.nom_usuel ? item.entreprise.nom_usuel : "company image" }), _jsx(IconContainer, { children: ((_c = item.entreprise) === null || _c === void 0 ? void 0 : _c.badge) && _jsx("img", { src: item.entreprise.badge, width: 24, height: 24, alt: "logo" }) })] })) })) })), _jsx("div", __assign({ className: "col-7" }, { children: _jsxs(Description, { children: [_jsx(CompanyTitle, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode == "fr" ? item.entreprise_absolute_url_fr : item.entreprise_absolute_url_en) }, { children: item.entreprise.nom_usuel })) }), _jsxs(Category, { children: [(_e = (_d = item.entreprise) === null || _d === void 0 ? void 0 : _d.code_scian_primaire) === null || _e === void 0 ? void 0 : _e.map(function (scian, scian_i) {
                                                        return _jsx("li", { children: scian["title_".concat(langCode)] }, scian_i);
                                                    }), (_g = (_f = item.entreprise) === null || _f === void 0 ? void 0 : _f.code_scian_secondaire) === null || _g === void 0 ? void 0 : _g.map(function (secsian, secsian_i) {
                                                        return _jsx("li", { children: secsian["title_".concat(langCode)] }, secsian_i);
                                                    })] })] }) }))] }) })] }) }));
};
