import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import BonsCoupsContext from "../../../../application/Context/BonsCoupsContext";
import { BonsCoupsCard } from "../Cards/BonsCoupsCard";
import { InfoBar } from "../InfoBar";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../shared-components/NotFound";
import { Pagination } from "../../../react-components/bonscoups-components/Pagination";
export var BonsCoups = function () {
    var _a = useContext(BonsCoupsContext), bonsCoups = _a.bonsCoups, bonsCoupsIsLoading = _a.bonsCoupsIsLoading, count = _a.count;
    return bonsCoupsIsLoading ? (_jsx(Loading, {})) : bonsCoups.length > 0 ? (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), bonsCoups.map(function (item, i) {
                return (_jsx(BonsCoupsCard, { item: item }, i));
            }), _jsx(Pagination, {})] })) : (_jsxs(React.Fragment, { children: [_jsx(InfoBar, { elements: count }), _jsx(NotFound, {})] }));
};
