var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import DocLibContext from "../../../application/Context/DocLibContext";
import { motion } from "framer-motion";
import RemoteMultiSelect from "../shared-components/MultiSelect/RemoteMultiSelect";
export var Filters = function () {
    var _a;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var t = useTranslation(["news"]).t;
    var _b = useContext(DocLibContext), handleSelectedSectore = _b.handleSelectedSectore, selectedSector = _b.selectedSector, selectedCategories = _b.selectedCategories, handleSelectedCategory = _b.handleSelectedCategory, filtersIsLoading = _b.filtersIsLoading;
    return (_jsx(React.Fragment, { children: _jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: [_jsx("label", __assign({ htmlFor: "react-select-2-input", style: { display: "none" } }, { children: "Types de documentation" })), _jsx(RemoteMultiSelect, { title: t("Types de documentation"), placeHolder: t("Sélectionner un ou des types"), handleClick: handleSelectedCategory, SelectedChoices: selectedCategories, disabled: filtersIsLoading, relatedField: "categorie", ESindex: "Documents", column: "nom_".concat(langCode), operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option["nom_".concat(langCode)]; } }), _jsx("label", __assign({ htmlFor: "react-select-3-input", style: { display: "none" } }, { children: "Secteurs" })), _jsx(RemoteMultiSelect, { title: t("Secteurs"), placeHolder: t("Sélectionner un ou des secteurs"), handleClick: handleSelectedSectore, SelectedChoices: selectedSector, disabled: filtersIsLoading, relatedField: "sector", ESindex: "Documents", column: "title_".concat(langCode), operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option["title_".concat(langCode)]; } })] })) }));
};
