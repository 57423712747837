var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var WorkersContext = createContext();
export var WorkersProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState(""), 2), searchInput = _d[0], setSearchInput = _d[1];
    var _e = __read(useState(""), 2), searchedTerm = _e[0], setSearchedTerm = _e[1];
    var _f = __read(useState([]), 2), workers = _f[0], setWorkers = _f[1];
    var _g = __read(useState(false), 2), workersIsLoading = _g[0], setWorkersIsLoading = _g[1];
    var _h = __read(useState([
        {
            order: 100000001,
            condition: "and",
            field: "entreprise.is_member",
            operator: "is",
            value: true,
        },
        {
            order: 100000002,
            condition: "and",
            field: "entreprise.status",
            operator: "is",
            value: ["actived", "imported"],
        }
    ]), 2), rows = _h[0], setRows = _h[1];
    var _j = __read(useState({}), 2), rowsSearchData = _j[0], setRowsSearchData = _j[1];
    var _k = __read(useState(false), 2), advSearch = _k[0], setAdvSearch = _k[1];
    var _l = __read(React.useState(0), 2), count = _l[0], setCount = _l[1];
    var _m = __read(React.useState(0), 2), page = _m[0], setPage = _m[1];
    var _o = __read(React.useState(1), 2), pageCount = _o[0], setPageCount = _o[1];
    // Get Workers
    useEffect(function () {
        sortArray(rows, {
            by: ["order"],
        });
        if (rows.length > 0 && rows[0].condition !== "where") {
            rows[0].condition = "where";
        }
        for (var i = 1; i < rows.length; i++) {
            if (rows[i].field !== rows[i - 1].field) {
                rows[i].condition = "and";
            }
        }
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [
                {
                    Worker: {
                        conditions: rows,
                        sort: {
                            "user_name_to_str.keyword": {
                                order: "asc",
                            }
                        }
                    }
                }
            ]
        };
        setRowsSearchData({ conditions: rows });
        if (!advSearch)
            fetchWorkers(searchData);
    }, [rows, page, searchedTerm, randQuery]);
    // Fetch Workers
    var fetchWorkers = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json"
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data)
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setWorkersIsLoading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                var results = data.results.map(function (data) {
                    return data.result;
                });
                setCount(data.count);
                setWorkers(results);
                setWorkersIsLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    // Search
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 8; }
        if (tab != 8)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    var searchWorkers = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    var resetFilters = function () {
        setRows([
            {
                order: 100000001,
                condition: "and",
                field: "entreprise.is_member",
                operator: "is",
                value: true,
            },
            {
                order: 100000002,
                condition: "and",
                field: "entreprise.status",
                operator: "is",
                value: ["actived", "imported"],
            }
        ]);
    };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    return (_jsx(WorkersContext.Provider, __assign({ value: {
            workers: workers,
            workersIsLoading: workersIsLoading,
            searchWorkers: searchWorkers,
            setSearchInput: setSearchInput,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            resetFilters: resetFilters,
            handleKeyDown: handleKeyDown,
            handlePageClick: handlePageClick,
            pageCount: pageCount,
            page: page,
            searchInput: searchInput,
            count: count,
            rows: rows,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery
        } }, { children: children })));
};
export default WorkersContext;
