import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import AdvancedSearchContext from "../../../../application/Context/AdvancedSearchContext";
import { All } from "./All";
import { Avis } from "./Avis";
import { Marche } from "./Marche";
import { Members } from "./Members";
import { News } from "./News";
import { Documents } from "./Documents";
import { Events } from "./Events";
import { BonsCoups } from "./BonsCoups";
import { Contact } from "./Contact";
export var Views = function () {
    var tab = useContext(AdvancedSearchContext).tab;
    var SwitchPage = function () {
        var RenderedTab;
        switch (tab) {
            case 0:
                RenderedTab = _jsx(All, {});
                break;
            case 1:
                RenderedTab = _jsx(Avis, {});
                break;
            case 2:
                RenderedTab = _jsx(Marche, {});
                break;
            case 3:
                RenderedTab = _jsx(Members, {});
                break;
            case 4:
                RenderedTab = _jsx(News, {});
                break;
            case 5:
                RenderedTab = _jsx(Documents, {});
                break;
            case 6:
                RenderedTab = _jsx(Events, {});
                break;
            case 7:
                RenderedTab = _jsx(BonsCoups, {});
                break;
            case 8:
                RenderedTab = _jsx(Contact, {});
                break;
        }
        return RenderedTab;
    };
    return (_jsx(React.Fragment, { children: _jsx(SwitchPage, {}) }));
};
