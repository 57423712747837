var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Tag, ImageContainer, Title } from "../../Styles/SharedStyledComponents";
import { Action, Container } from "../Views/views-styles";
import { Row } from "../../shared-components/Ui/Row";
import { sliceStringAfterMaxLength } from "../../../../utils/Utils";
export var DocumentsCard = function (_a) {
    var _b;
    var item = _a.item;
    var t = useTranslation(["common"]).t;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var getFileUrl = function (item) {
        if (langCode === 'fr') {
            return item.file_fr || item.file_en;
        }
        else {
            return item.file_en || item.file_fr;
        }
    };
    var getDescription = function (item) {
        if (langCode === 'fr') {
            return item.description_item_fr || item.description_doc_en;
        }
        else {
            return item.description_doc_en || item.description_item_fr;
        }
    };
    var description = getDescription(item);
    var fileUrl = getFileUrl(item);
    return (_jsx(React.Fragment, { children: _jsx(Container, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(langCode == "fr" ? item.absolute_url_fr : item.absolute_url_en) }, { children: _jsx(ImageContainer, __assign({ width: 20, height: 0, marginBottom: 14 }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner" }) })) })) })), _jsxs("div", __assign({ className: "col-md-7 mb-3 mb-md-0" }, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: "".concat(langCode == "fr" ? item.absolute_url_fr : item.absolute_url_en) }, { children: langCode == "fr" ? item.titre_doc_fr : item.titre_doc_en })) }), _jsxs(Text, { children: [_jsx("p", { children: sliceStringAfterMaxLength(120, description) }), _jsxs("div", __assign({ className: "flex-wrap" }, { children: [item.categorie &&
                                                item.categorie.map(function (cat) { return (_jsxs(Tag, { children: [" ", cat["nom_".concat(langCode)], " "] }, cat.id)); }), item.sector && _jsxs(Tag, { children: [" ", item.sector["title_".concat(langCode)], " "] })] }))] }), _jsxs(Action, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/download-b.svg"), alt: "download" }), _jsx("a", __assign({ href: "".concat(fileUrl), download: true, target: "_blank", rel: "noreferrer" }, { children: t("Download File") }))] })] }))] }) }) }));
};
