var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useState, useEffect } from "react";
import React from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var DocLibContext = createContext();
export var DocLibProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    //Search State
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState(""), 2), searchInput = _d[0], setSearchInput = _d[1];
    var _e = __read(useState(""), 2), searchedTerm = _e[0], setSearchedTerm = _e[1];
    //Filter State
    var _f = __read(useState([]), 2), selectedSector = _f[0], setSelectedSector = _f[1];
    var _g = __read(useState([]), 2), sectors = _g[0], setSectors = _g[1];
    var _h = __read(useState([]), 2), categories = _h[0], setCategories = _h[1];
    var _j = __read(useState([]), 2), selectedCategories = _j[0], setSelectedCategories = _j[1];
    //Documents state
    var _k = __read(useState([]), 2), docs = _k[0], setDocs = _k[1];
    var _l = __read(useState(false), 2), docsIsLoading = _l[0], setDocsIsLoading = _l[1];
    var _m = __read(useState(false), 2), filtersIsLoading = _m[0], setFiltersIsLoading = _m[1];
    var _o = __read(useState({}), 2), rowsSearchData = _o[0], setRowsSearchData = _o[1];
    var _p = __read(useState(false), 2), advSearch = _p[0], setAdvSearch = _p[1];
    //query
    var _q = __read(useState([]), 2), rows = _q[0], setRows = _q[1];
    //Docs Count
    var _r = __read(React.useState(0), 2), count = _r[0], setCount = _r[1];
    // Pagination State
    var _s = __read(React.useState(0), 2), page = _s[0], setPage = _s[1];
    var _t = __read(React.useState(1), 2), pageCount = _t[0], setPageCount = _t[1];
    // Get fields
    useEffect(function () {
        fetchField();
    }, []);
    var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, sectors, categories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFiltersIsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Documents&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    sectors = data.filter(function (obj) { return obj.field_name == "sector"; });
                    categories = data.filter(function (obj) { return obj.field_name == "categorie"; });
                    setSectors(sectors[0].values);
                    setCategories(categories[0].values);
                    setFiltersIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Get Documents
    useEffect(function () {
        var _a, _b;
        //order by first selected filter
        sortArray(rows, {
            by: ["order"],
        });
        // First, check if the first element is not "where"
        if (rows.length > 0 && rows[0].condition !== "where") {
            rows[0].condition = "where";
        }
        for (var i = 1; i < rows.length; i++) {
            if (rows[i].field !== rows[i - 1].field) {
                rows[i].condition = "and";
            }
        }
        var sortTitleByLang = langCode == "en" ? (_a = {},
            _a["titre_doc_en.keyword"] = { order: "asc" },
            _a) : (_b = {},
            _b["titre_doc_fr.keyword"] = { order: "asc" },
            _b);
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [
                {
                    Documents: {
                        conditions: rows,
                        sort: sortTitleByLang,
                    },
                },
            ],
        };
        setRowsSearchData({ conditions: rows });
        if (!advSearch)
            fetchDocs(searchData);
    }, [rows, page, searchedTerm, randQuery]);
    //Fetch Docs
    var fetchDocs = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data), // body data type must match "Content-Type" header
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setDocsIsLoading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                var docs = data.results.map(function (data) {
                    return data.result;
                });
                setCount(data.count);
                setDocs(docs);
                setDocsIsLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    //Search docs
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 5; }
        if (tab != 5)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    var searchDocs = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    // highest order
    function getHighestOrder(array) {
        var highestPrice = 99999999;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestPrice) {
                highestPrice = array[i].order;
            }
        }
        return highestPrice;
    }
    //Handle sectore Change
    var handleSelectedSectore = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedSector
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedSector.includes(x); }));
        var newSector = {
            order: 99999999,
            condition: "or",
            field: "sector.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedSector.length) {
            setSelectedSector(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newSector], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("sector.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "sector.id"; });
                newSector.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newSector.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_1 = selectedSector
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedSector.includes(x); }));
            setSelectedSector(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.value !== removedOption_1[0].value;
                });
            });
        }
    };
    //Handle category Change
    var handleSelectedCategory = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedCategories
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
        var newSector = {
            order: 99999999,
            condition: "or",
            field: "categorie.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedCategories.length) {
            setSelectedCategories(item);
            setRows(function (prevStat) {
                return __spreadArray(__spreadArray([], __read(prevStat), false), [newSector], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("categorie.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "categorie.id"; });
                newSector.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newSector.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_2 = selectedCategories
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
            setSelectedCategories(item);
            setRows(function () {
                return rows.filter(function (item) {
                    return item.value !== removedOption_2[0].value;
                });
            });
        }
    };
    var resetFilters = function () {
        setSelectedSector([]);
        setSelectedCategories([]);
        setRows([]);
    };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    return (_jsxs(DocLibContext.Provider, __assign({ value: {
            searchDocs: searchDocs,
            setSearchInput: setSearchInput,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            handleSelectedSectore: handleSelectedSectore,
            resetFilters: resetFilters,
            handleSelectedCategory: handleSelectedCategory,
            handleKeyDown: handleKeyDown,
            handlePageClick: handlePageClick,
            rows: rows,
            pageCount: pageCount,
            page: page,
            selectedCategories: selectedCategories,
            searchInput: searchInput,
            selectedSector: selectedSector,
            docs: docs,
            docsIsLoading: docsIsLoading,
            filtersIsLoading: filtersIsLoading,
            count: count,
            sectors: sectors,
            categories: categories,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery,
        } }, { children: [" ", children, " "] })));
};
export default DocLibContext;
