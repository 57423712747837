var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Text, Tag, ImageContainer, DateContainer, NewsContainer, NewsTitle, } from "../../Styles/SharedStyledComponents";
import { sliceStringAfterMaxLength } from "../../../../utils/Utils";
import { Row } from "../../shared-components/Ui/Row";
export var NewsCard = function (_a) {
    var _b;
    var item = _a.item;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = langCode == "fr" ? "/fr/nouvelles" : "/en/news";
    var options = { year: "numeric", month: "long" };
    var f = Intl.DateTimeFormat(langCode, options);
    return (_jsx(React.Fragment, { children: _jsx(NewsContainer, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(langCode === "fr" ? item.slug_fr : item.slug_en) }, { children: _jsx(ImageContainer, __assign({ marginBottom: 14 }, { children: _jsx("img", { src: item.thumbnail_url ? item.thumbnail_url : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }) })) })) })), _jsx("div", __assign({ className: "col-md-7 mb-3 mb-md-0" }, { children: _jsxs(Text, { children: [_jsx(DateContainer, { children: f.format(new Date((item.date_creation_annee +
                                        "-" +
                                        item.date_creation_mois +
                                        "-12").replace(/-/g, "/" + " "))) }), _jsx(NewsTitle, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(langCode === "fr" ? item.slug_fr : item.slug_en) }, { children: sliceStringAfterMaxLength(40, langCode === "fr" ? item.titre_fr : item.titre_en) })) }), _jsx("p", { children: sliceStringAfterMaxLength(120, langCode === "fr" ? item.description_fr : item.description_en) }), _jsx("div", { children: item.categorie.map(function (cat) {
                                        return (_jsx("a", __assign({ href: "/".concat(langCode, "/").concat(langCode == "en" ? "news/" : "nouvelles/", "?category=").concat(cat.id) }, { children: _jsx(Tag, { children: langCode == "fr" ? cat.nom_fr : cat.nom_en }) }), cat.id));
                                    }) })] }) }))] }) }) }));
};
