var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import ProjectsContext from "./ProjectsContext";
import MarketsContext from "./MarketsContext";
import MembersContext from "./MembersContext";
import NewsSearchContext from "./NewsSearchContext";
import DocLibContext from "./DocLibContext";
import EventsSearchContext from "./EventsSearchContext";
import BonsCoupsContext from "./BonsCoupsContext";
import WorkersContext from "./WorkersContext";
var AdvancedSearchContext = createContext();
var navigationItems = [
    "Tous",
    "Avis",
    "Marchés",
    "Membres",
    "Nouvelles",
    "Documents",
    "Événements",
    "Bons Coups",
    "Contacts",
];
var navigationItemsParam = [
    "all",
    "notices",
    "markets",
    "members",
    "news",
    "documents",
    "events",
    "bonscoups",
    "contacts",
];
export var AdvancedSearchProvider = function (_a) {
    var children = _a.children;
    var _b = React.useContext(ProjectsContext), ProjectsSearch = _b.rowsSearchData, ProjectsSetRandQuery = _b.setRandQuery, ProjectsSetAdvSearch = _b.setAdvSearch;
    var _c = React.useContext(MarketsContext), MarketsSearch = _c.rowsSearchData, MarketsSetRandQuery = _c.setRandQuery, MarketsSetAdvSearch = _c.setAdvSearch;
    var _d = React.useContext(MembersContext), MembersSearch = _d.rowsSearchData, MembersSetRandQuery = _d.setRandQuery, MembersSetAdvSearch = _d.setAdvSearch;
    var _e = React.useContext(NewsSearchContext), NewsSearch = _e.rowsSearchData, NewsSetRandQuery = _e.setRandQuery, NewsSetAdvSearch = _e.setAdvSearch;
    var _f = React.useContext(DocLibContext), DocsSearch = _f.rowsSearchData, DocsSetRandQuery = _f.setRandQuery, DocsSetAdvSearch = _f.setAdvSearch;
    var _g = React.useContext(EventsSearchContext), EventsSearch = _g.rowsSearchData, EventsSetRandQuery = _g.setRandQuery, EventsSetAdvSearch = _g.setAdvSearch;
    var _h = React.useContext(BonsCoupsContext), BonsCoupsSearch = _h.rowsSearchData, BonsCoupsSetRandQuery = _h.setRandQuery, BonsCoupsSetAdvSearch = _h.setAdvSearch;
    var _j = React.useContext(WorkersContext), WorkersSearch = _j.rowsSearchData, WorkersSetRandQuery = _j.setRandQuery, WorkersSetAdvSearch = _j.setAdvSearch;
    var _k = __read(React.useState(0), 2), page = _k[0], setPage = _k[1];
    var _l = __read(React.useState(0), 2), tab = _l[0], setTab = _l[1];
    var _m = __read(React.useState(0), 2), randQuery = _m[0], setRandQuery = _m[1];
    var _o = __read(React.useState("Tous"), 2), displayedFilter = _o[0], setDisplayedFilter = _o[1];
    var _p = __read(useState(false), 2), dataIsLoading = _p[0], setDataIsloading = _p[1];
    var _q = __read(useState([]), 2), data = _q[0], setData = _q[1];
    var _r = __read(React.useState(0), 2), count = _r[0], setCount = _r[1];
    var _s = __read(React.useState(1), 2), pageCount = _s[0], setPageCount = _s[1];
    var _t = __read(React.useState(""), 2), searchInput = _t[0], setSearchInput = _t[1];
    var _u = __read(useState(""), 2), searchedTerm = _u[0], setSearchedTerm = _u[1];
    useEffect(function () {
        // order by first selected filter
        var projectSearchConditions = [];
        if ("conditions" in ProjectsSearch) {
            projectSearchConditions = __spreadArray(__spreadArray([], __read(projectSearchConditions), false), __read(ProjectsSearch["conditions"].filter(function (e) {
                return e.field != "is_avis";
            })), false);
        }
        if ("conditions" in MarketsSearch) {
            projectSearchConditions = __spreadArray(__spreadArray([], __read(projectSearchConditions), false), __read(MarketsSearch["conditions"].filter(function (e) {
                return e.field != "is_avis";
            })), false);
        }
        var searchData = {
            search_fields: {
                "nom_usuel_fr": { weight: 10 },
                "nom_legal_fr": { weight: 9 },
                "nom_usuel_en": { weight: 10 },
                "nom_legal_en": { weight: 9 },
                "nom_fr": { weight: 10 },
                "nom_en": { weight: 10 },
                "titre_fr": { weight: 10 },
                "titre_en": { weight: 10 },
                "titre_doc_fr": { weight: 10 },
                "titre_doc_en": { weight: 10 },
                "titre_event_fr": { weight: 10 },
                "titre_event_en": { weight: 10 },
                "titre": { weight: 10 },
                "user_name_to_str": { weight: 10 },
                "entreprise.nom_usuel_fr": { weight: 9 },
                "entreprise.nom_legal_fr": { weight: 9 },
                "entreprise.nom_usuel_en": { weight: 9 },
                "entreprise.nom_legal_en": { weight: 9 },
                "user_email_to_str": { weight: 9 },
                "truncate_description": { weight: 2 },
                "titre_pending": { weight: 0 },
                "description_pending": { weight: 0 },
            },
            query: searchedTerm,
            page: page + 1,
            filters: [
                { Project: {
                        conditions: projectSearchConditions,
                    } },
                { Company: MembersSearch },
                { New: NewsSearch },
                { Documents: DocsSearch },
                { Event: EventsSearch },
                { BonCoup: BonsCoupsSearch },
                { Worker: WorkersSearch }
            ],
        };
        if (tab == 0 &&
            !dataIsLoading &&
            "conditions" in NewsSearch &&
            "conditions" in MembersSearch &&
            "conditions" in DocsSearch &&
            "conditions" in EventsSearch &&
            "conditions" in BonsCoupsSearch &&
            "conditions" in WorkersSearch) {
            fetchData(searchData);
        }
    }, [
        randQuery,
        page,
        tab,
        searchedTerm,
        ProjectsSearch,
        MembersSearch,
        NewsSearch,
        DocsSearch,
        EventsSearch,
        BonsCoupsSearch,
        WorkersSearch
    ]);
    // Fetch Data
    var fetchData = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/api/search/"; }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setDataIsloading(true);
                            return [4 /*yield*/, fetch(url, {
                                    method: "POST",
                                    mode: "cors",
                                    cache: "no-cache",
                                    credentials: "same-origin",
                                    headers: {
                                        "X-CSRFToken": CSRF_TOKEN,
                                        "Content-Type": "application/json",
                                        // 'Content-Type': 'application/x-www-form-urlencoded',
                                    },
                                    redirect: "follow",
                                    referrerPolicy: "no-referrer",
                                    body: JSON.stringify(data),
                                    signal: controller.signal,
                                })];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            result = _a.sent();
                            return [2 /*return*/, result];
                    }
                });
            });
        }
        var controller, CSRF_TOKEN;
        return __generator(this, function (_a) {
            controller = new AbortController();
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/api/search/", data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                //const mixedArray = [];
                var allData = data.results.map(function (data) {
                    return data.result;
                });
                var tables = data.results.map(function (data) {
                    return data._index;
                });
                var newArray = allData.map(function (object, index) {
                    return __assign(__assign({}, object), { table: tables[index] });
                });
                setData(newArray);
                setCount(data.count);
                setDataIsloading(false);
            });
            return [2 /*return*/, function () { return controller === null || controller === void 0 ? void 0 : controller.abort(); }];
        });
    }); };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    //Handle tab change
    var handleTab = function (e) {
        setTab(parseInt(e.target.id));
        ProjectsSetAdvSearch(true);
        MarketsSetAdvSearch(true);
        MembersSetAdvSearch(true);
        NewsSetAdvSearch(true);
        DocsSetAdvSearch(true);
        EventsSetAdvSearch(true);
        BonsCoupsSetAdvSearch(true);
        WorkersSetAdvSearch(true);
        ProjectsSetRandQuery(Math.random());
        MarketsSetRandQuery(Math.random());
        MembersSetRandQuery(Math.random());
        NewsSetRandQuery(Math.random());
        DocsSetRandQuery(Math.random());
        EventsSetRandQuery(Math.random());
        BonsCoupsSetRandQuery(Math.random());
        WorkersSetRandQuery(Math.random());
        if (parseInt(e.target.id) == 1) {
            ProjectsSetAdvSearch(false);
            ProjectsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 2) {
            MarketsSetAdvSearch(false);
            MarketsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 3) {
            MembersSetAdvSearch(false);
            MembersSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 4) {
            NewsSetAdvSearch(false);
            NewsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 5) {
            DocsSetAdvSearch(false);
            DocsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 6) {
            EventsSetAdvSearch(false);
            EventsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 7) {
            BonsCoupsSetAdvSearch(false);
            BonsCoupsSetRandQuery(Math.random());
        }
        if (parseInt(e.target.id) == 8) {
            WorkersSetAdvSearch(false);
            WorkersSetRandQuery(Math.random());
        }
        setPage(0);
        setDisplayedFilter(navigationItems[parseInt(e.target.id)]);
    };
    // Search
    var handleSearchInput = function (e) {
        setSearchInput(e);
    };
    var handleSearch = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    return (_jsx(AdvancedSearchContext.Provider, __assign({ value: {
            navigationItems: navigationItems,
            navigationItemsParam: navigationItemsParam,
            displayedFilter: displayedFilter,
            handlePageClick: handlePageClick,
            page: page,
            tab: tab,
            handleTab: handleTab,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            handleSearch: handleSearch,
            handleKeyDown: handleKeyDown,
            dataIsLoading: dataIsLoading,
            data: data,
            count: count,
            pageCount: pageCount,
            randQuery: randQuery,
            setRandQuery: setRandQuery,
        } }, { children: children })));
};
export default AdvancedSearchContext;
