var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Text, CompanyImageContainer, IconContainerBig, CompanyDetails, Title, Category, } from "../../Styles/SharedStyledComponents";
import { Row } from "../../shared-components/Ui/Row";
import { sliceStringAfterMaxLength } from "../../../../utils/Utils";
export var CompaniesCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var item = _a.item;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = langCode == "fr" ? "/fr" : "/en";
    function getNomLegal(langCode, company) {
        if (langCode === "fr") {
            return company.nom_legal_fr || company.nom_legal_en || company.nom_legal;
        }
        else {
            return company.nom_legal_en || company.nom_legal_fr || company.nom_legal;
        }
    }
    return (_jsx(React.Fragment, { children: _jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode == "fr" ? item.absolute_url_fr : item.absolute_url_en) }, { children: _jsxs(CompanyImageContainer, __assign({ marginBottom: 14 }, { children: [_jsx("img", { src: item.logo_url
                                            ? item.logo_url
                                            : process.env.IMG_URL +
                                                "/static/vendors/images/default-Image-company.png", alt: "banner" }), _jsx(IconContainerBig, { children: _jsx("img", { src: item.badge_url, width: 24, height: 24, alt: item.type_company ? item.type_company : "company badge" }) })] })) })) })), _jsx("div", __assign({ className: "col-md-7 mb-3 mb-md-0" }, { children: _jsxs(Text, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode == "fr"
                                            ? item.absolute_url_fr
                                            : item.absolute_url_en) }, { children: getNomLegal(langCode, item) })) }), _jsx(Category, { children: item.code_scian_primaire.map(function (scian, scian_i) {
                                        return _jsxs("li", { children: [scian["code"], " - ", scian["title_".concat(langCode)]] }, scian_i);
                                    }) }), (((_d = (_c = item === null || item === void 0 ? void 0 : item.adresses) === null || _c === void 0 ? void 0 : _c.mrc) === null || _d === void 0 ? void 0 : _d.nom) !== "HORS-MRC" && ((_e = item === null || item === void 0 ? void 0 : item.adresses) === null || _e === void 0 ? void 0 : _e.region_adminsitrative)) ||
                                    (((_g = (_f = item === null || item === void 0 ? void 0 : item.adresses) === null || _f === void 0 ? void 0 : _f.region_adminsitrative) === null || _g === void 0 ? void 0 : _g.nom) || ((_j = (_h = item === null || item === void 0 ? void 0 : item.adresses) === null || _h === void 0 ? void 0 : _h.province) === null || _j === void 0 ? void 0 : _j.nom)) ? (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsx("span", { children: ((_l = (_k = item === null || item === void 0 ? void 0 : item.adresses) === null || _k === void 0 ? void 0 : _k.mrc) === null || _l === void 0 ? void 0 : _l.nom) !== "HORS-MRC" && ((_m = item === null || item === void 0 ? void 0 : item.adresses) === null || _m === void 0 ? void 0 : _m.region_adminsitrative) ?
                                                "".concat(item.adresses.mrc.nom, ", ").concat(item.adresses.region_adminsitrative.nom)
                                                : (((_p = (_o = item === null || item === void 0 ? void 0 : item.adresses) === null || _o === void 0 ? void 0 : _o.region_adminsitrative) === null || _p === void 0 ? void 0 : _p.nom) && ((_r = (_q = item === null || item === void 0 ? void 0 : item.adresses) === null || _q === void 0 ? void 0 : _q.province) === null || _r === void 0 ? void 0 : _r.nom) ?
                                                    "".concat(item.adresses.region_adminsitrative.nom, ", ").concat(item.adresses.province.nom)
                                                    : ((_t = (_s = item === null || item === void 0 ? void 0 : item.adresses) === null || _s === void 0 ? void 0 : _s.region_adminsitrative) === null || _t === void 0 ? void 0 : _t.nom) || ((_v = (_u = item === null || item === void 0 ? void 0 : item.adresses) === null || _u === void 0 ? void 0 : _u.province) === null || _v === void 0 ? void 0 : _v.nom) || "") })] })) : null, item.description_publique && (_jsx("p", { children: sliceStringAfterMaxLength(120, item.description_publique) }))] }) }))] }) }) }));
};
